import React from 'react'
import { SocialNative } from '@vp/social-native-component'
import { BoundedContent, Box, Column, GridContainer, Row, H2, Typography } from '@vp/swan'
import { ContentfulContextProvider, DebugSection, ContentfulLink } from '@vp/mcs-react-tools'
import { IOlaPicData, AnalyticsData } from '../types'

export const shouldShowSocialCurations = (data:IOlaPicData, isLoggedIn: boolean, enableSocialCurationsForLoggedInCustomers: boolean) => {
  const dataIsWellFormed = !!data?.apiKey && !!data.widget?.instanceId && !!data.source?.id
  return (!isLoggedIn || enableSocialCurationsForLoggedInCustomers) && dataIsWellFormed
}

const search = typeof window !== 'undefined' ? window.location.search : ''

export const SocialCurations = ({ data, analyticsData, locale, heading, subheading, enableSocialCurationsForLoggedInCustomers, isLoggedIn, contentfulMetadata, debugAvailability }: Props) => {
  return (
    <ContentfulContextProvider space={contentfulMetadata?.spaceId}>
      <ContentfulLink id={contentfulMetadata?.contentful_id} />
      <DebugSection
        contentfulMetadata={contentfulMetadata}
        availability={debugAvailability}
        search={search}
      />
      {shouldShowSocialCurations(data, isLoggedIn, enableSocialCurationsForLoggedInCustomers) && (
        <BoundedContent id='socialCurations' mb='between-sections'>
          <Box>
            <GridContainer mb='6'>
              <Row>
                <Column span={12} pl='3'>
                  <H2 fontSkin='title-section' mb='2'>
                    {heading}
                  </H2>
                </Column>
              </Row>
              <Row>
                <Column span={12} pl='3'>
                  <Typography component='p' fontSkin='body-standard' mt='0' mb='0'>
                    {subheading}
                  </Typography>
                </Column>
              </Row>
            </GridContainer>
          </Box>
          <Box>
            <GridContainer>
              <Row key={1}>
                <Column span={12}>
                  <SocialNative widgetData={data} analyticsData={analyticsData} locale={locale} />
                </Column>
              </Row>
            </GridContainer>
          </Box>
        </BoundedContent>
      )}
    </ContentfulContextProvider>
  )
}

export interface Props {
  heading: string
  subheading: string
  data: IOlaPicData
  analyticsData: AnalyticsData
  locale: string,
  enableSocialCurationsForLoggedInCustomers: boolean,
  isLoggedIn: boolean
  contentfulMetadata: any,
  debugAvailability: any
}
